@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400&display=swap");

body {
    font-family: "Poppins", sans-serif;
    background-color: #141414;
    display: flex;
    justify-content: center;
    flex-direction: column;
    min-height: 100vh;
    margin: auto;
    align-items: center;
}

a {
    text-decoration: none;
    color: rgb(184, 224, 255);
    cursor: pointer;
}

p,
h3 {
    margin: 0;
}

a:hover {
    text-decoration: underline;
}

h1 {
    text-align: center;
}

.container {
    width: 75%;
    max-width: 1280px;
    margin: auto;
}

@media (max-width: 1028px) {
    .container {
        width: 96%;
    }
}

.App {
    color: #fff;
}

.news {
    display: flex;
    place-items: center;
    gap: 20px;
    background-color: rgba(200, 200, 200, 0.1);
    color: #fff;
    margin: 10px;
    padding: 8px;
    border: 1px black;
    border-style: solid;
    border-radius: 8px;
}

.news_picture {
    width: 300px;
    height: 200px;
}

.news img {
    border-radius: 8px;
    object-fit: cover;
    height: 200px;
    width: 300px;
}

.news_time {
    font-size: 14px;
    color: #818384;
}

.news_desc {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.news .domain {
    font-size: 14px;
    margin-top: 16px;
    color: #818384;
    opacity: 0;
}

.news:hover {
    border-color: rgb(36, 137, 208);
}

.news:hover .domain {
    opacity: 1;
}

.news_content {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.sub {
    margin: 10px;
}

input {
    padding: 10px;
    margin-right: 5px;
}

.btn {
    background: rgb(36, 149, 5);
    color: #fff;
    padding: 10px 20px;
    margin: 5px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-decoration: none;
    font-size: 16px;
    position: relative;
}

.btn:hover {
    background: rgb(29, 130, 2);
}

.btn:disabled {
    background: grey;
    cursor: progress;
    padding: 10px 20px;
    margin: 5px;
    color: transparent;
}

.btn--loading::after {
    content: "";
    position: absolute;
    width: 16px;
    height: 16px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    border: 4px solid transparent;
    border-top-color: #ffffff;
    border-radius: 50%;
    animation: button-loading-spinner 1s ease infinite;
}

@keyframes button-loading-spinner {
    from {
        transform: rotate(0turn);
    }

    to {
        transform: rotate(1turn);
    }
}

.inputField {
    border-radius: 5px;
    font-size: 16px;
    border: none;
}

/*
Tags
*/
ul.tags {
    list-style-type: none;
    padding: 0;
}

ul.tags li {
    display: inline-block;
}

ul.tags li a {
    display: inline-block;
    margin: 0.5rem 0rem;
    margin-right: 0.5rem;
    background: rgba(200, 200, 200, 0.2);
    border: 0.2rem rgba(0, 0, 0, 0.2) solid;
    padding: 0.25rem 1rem;
    border-radius: 100rem;
    color: #eee;
}

ul.tags li a:hover {
    background: rgba(200, 200, 200, 0.4);
    color: #fff;
    text-decoration: none;
}

.footer_text {
    padding: 1rem 0 1rem 0;
    font-size: 18px;
    display: flex;
    justify-content: center;
}

.footer_text a {
    text-decoration: underline;
    text-underline-offset: 2.4px;
    color: #fff;
}

.footer_text a:hover {
    text-underline-offset: 6px;
    color: rgb(184, 224, 255);
    transition: all 250ms ease;
}
